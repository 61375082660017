export default {
  namespaced: true,
  state: {
    postorders: {
      fetchingList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_POSTORDERS_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_POSTORDERS_LIST')
      state.postorders.fetchingList = newVal
      console.log(state.postorders.fetchingList)
    },
  },
  actions: {},
}
