import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import users from './users'
import clients from './clients'
import abilitiestemplates from './abilitiestemplates'
import camerasManagement from './camerasManagement'
import cameraconsole from './cameraconsole'
import logs from './logs'
import firmware from './firmware'
import cameraDownloads from './cameraDownloads'
import userSettings from './usersettings'
import reports from './reports'
import postorders from './postorders'
import corporatepolicy from './corporatepolicy'
import parkingpermit from './parkingpermit'
import alerts from './alerts'
import patrols from './patrols'
import onboarding from './onboarding'
import submissions from './submissions'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
    users,
    clients,
    abilitiestemplates,
    camerasManagement,
    cameraconsole,
    logs,
    firmware,
    cameraDownloads,
    userSettings,
    reports,
    postorders,
    corporatepolicy,
    parkingpermit,
    alerts,
    patrols,
    onboarding,
    submissions,
  },
  strict: process.env.DEV,
})
