export default {
  namespaced: true,
  state: {
    alerts: {
      fetchingList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_ALERTS_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_ALERTS_LIST')
      state.alerts.fetchingList = newVal
      console.log(state.alerts.fetchingList)
    },
  },
  actions: {},
}
