export default {
  namespaced: true,
  state: {
    submissions: {
      fetchingList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_SUBMISSIONS_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_SUBMISSIONS_LIST')
      state.submissions.fetchingList = newVal
      console.log(state.submissions.fetchingList)
    },
  },
  actions: {},
}
