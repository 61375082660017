export default {
  namespaced: true,
  state: {
    corporatepolicy: {
      fetchingList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_CORPORATEPOLICY_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_CORPORATEPOLICY_LIST')
      state.corporatepolicy.fetchingList = newVal
      console.log(state.corporatepolicy.fetchingList)
    },
  },
  actions: {},
}
