export default {
  isCordova: false,
  nfc: null,
  ndef: null,
  currentNFCListenerCallback: null,

  replaceCurrentNdefListener(newCallback) {
    this.nfc.removeNdefListener(
      this.currentNFCListenerCallback,
      () => {
        console.log('successfully removed listener callback: writeTag()')

        this.currentNFCListenerCallback = newCallback // make the new callback the current callback

        this.nfc.addNdefListener(
          this.currentNFCListenerCallback,
          this.nfcwin,
          this.nfcfail,
        )
      },
      () => {
        console.log('error: unable to remove listener callback')
      },
    )
  },

  nfcwin() {
    console.log('Success. Waiting for NFC Tag...')
  },
  nfcfail(error) {
    console.log(`Error adding NDEF Listener ${JSON.stringify(error)}`)
  },

  initCordova() {
    return new Promise(resolve => {
      console.log('Starting Cordova initialization')
      console.log('Document URL:', document.URL)
      console.log('Window Cordova:', window.cordova)
      console.log('Platform:', window.platform)

      // Wait for the deviceready event
      document.addEventListener('deviceready', () => {
        console.log('Device Ready Event Fired')
        this.isCordova = true
        this.cordova = window.cordova
        this.nfc = window.nfc
        this.ndef = window.ndef
        this.initNFC()
        console.log('NFC initialized')
        resolve()
      }, false)

      // Add a timeout to detect if deviceready never fires
      setTimeout(() => {
        if (!this.isCordova) {
          console.log('Deviceready event never fired - assuming browser environment')
          resolve()
        }
      }, 3000)
    })
  },

  initNFC() {
    console.log('Initializing NFC')
    if (this.nfc) {
      console.log('NFC is available')
    } else {
      console.log('NFC not available')
    }
  },

  checkNFCAvailability() {
    console.log('Checking NFC availability')
    console.log('isCordova:', this.isCordova)
    console.log('nfc object:', this.nfc)

    if (!this.isCordova) {
      console.log('Not a Cordova environment')
      return {
        available: false,
        message: 'NFC is only available on mobile devices',
      }
    }

    if (!this.nfc) {
      console.log('NFC not initialized')
      return {
        available: false,
        message: 'NFC is not available on this device',
      }
    }

    return {
      available: true,
      message: 'NFC is available',
    }
  },
}
