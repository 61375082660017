export default {
  namespaced: true,
  state: {
    parkingpermit: {
      fetchingList: true,
    },
  },
  getters: {},
  mutations: {
    UPDATE_FETCHING_PARKINGPERMITS_LIST(state, newVal) {
      console.log('UPDATE_FETCHING_PARKINGPERMITS_LIST')
      state.parkingpermit.fetchingList = newVal
      console.log(state.parkingpermit.fetchingList)
    },
  },
  actions: {},
}
