// import useAWS from '@/auth/aws/useAWS'
import AWSDefaultConfig from './aws/AWSDefaultConfig'
import ability from '../libs/acl/ability'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = () => {
  console.log('***** isUserLoggedIn *****')
  let myReturn = false
  try {
    const userData = localStorage.getItem('userData')
    if (typeof JSON.parse(userData).username !== 'undefined') {
      myReturn = true
    }
    const awsDC = AWSDefaultConfig.storageTokenKeyName
    if (typeof awsDC !== 'undefined' && awsDC === 'accessToken') {
      myReturn = true
    } else {
      myReturn = false
    }
  } catch (e) {
    myReturn = false
  }
  return myReturn
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  console.log(userRole)
  const currentLoggedInUserData = JSON.parse(localStorage.getItem('userData'))
  // console.log(currentLoggedInUserData)
  if (currentLoggedInUserData.Is_onboarding === true) return '/apps/onboarding/manage'
  if (ability.can('read', 'dashboard')) return '/dashboard/analytics'
  if (ability.can('read', 'reports')) return '/apps/reports/type'
  if (ability.can('read', 'users')) return '/apps/users/list'
  if (ability.can('read', 'clients')) return '/apps/clients/list'

  if (userRole === 'Management') return '/dashboard/analytics'
  if (userRole === 'Client_Admin') return '/dashboard/analytics'
  if (userRole === 'Client_Viewer') return '/dashboard/analytics'
  // if (userRole === 'client') return { name: 'access-control' }
  if (userRole === 'Client_Viewer') return { name: 'auth-login' }
  return '/dashboard/analytics'
}
